import { useEffect, useRef } from 'react';
import { render } from 'react-dom';

import './style.scss';
import Style from './Style';
import LottiePlayer from './Components/LottiePlayer';
import { toggleAttr } from './utils/functions';

// Lottie Player
document.addEventListener('DOMContentLoaded', () => {
	const allPlayers = document.querySelectorAll('.wp-block-lpb-lottie-player');
	allPlayers.forEach(player => {
		const attributes = JSON.parse(player.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<RenderLottiePlayer attributes={attributes} clientId={attributes.cId} />
		</>, player);

		player?.removeAttribute('data-attributes');
	});
});

const RenderLottiePlayer = ({ attributes, clientId }) => {
	const { isControls, isAutoplay, isLoop, isHover, link, isLinkInNewTab, interactivity, interactivityMode, interactivityContainer, interactivityActions, isCap, caption } = attributes;

	const lottieEl = useRef(null);

	useEffect(() => {
		// Set player
		const lottiePlayer = document.querySelector(`#lpbLottiePlayer-${clientId} .lpbLottiePlayer lottie-player`);

		toggleAttr(lottiePlayer, isControls, 'controls', '');
		toggleAttr(lottiePlayer, isAutoplay, 'autoplay', '');
		toggleAttr(lottiePlayer, isLoop, 'loop', '');
		toggleAttr(lottiePlayer, isHover, 'hover', '');
	}, []);

	// Interactivity
	useEffect(() => {
		const player = lottieEl?.current;

		if (player && interactivity && 'noInteractivity' !== interactivity) {
			player.addEventListener('ready', () => {
				LottieInteractivity.create({
					player: player.getLottie(),
					container: 'scrollRelativeToContainer' === interactivity ? interactivityContainer : `#lpbLottiePlayer-${clientId}`,
					mode: interactivityMode,
					actions: interactivityActions
				});
			});
		}
	}, [lottieEl?.current]);

	return <div className='lpbLottiePlayer'>
		{link ? <a href={link} target={isLinkInNewTab ? '_blank' : '_self'} rel='noreferrer'><LottiePlayer ref={lottieEl} attributes={attributes} /></a> : <LottiePlayer ref={lottieEl} attributes={attributes} />}

		{isCap && <><br /><span className='caption'>{caption}</span></>}
	</div>
}